import { requestWithToken } from "../../apiConfigs/request";

export const fetchAllProjects = () => {
  return requestWithToken({
    url: `/projects`,
    method: 'GET',
  });
}

export const createProject = (data) => {
  return requestWithToken({
    url: `/projects`,
    method: 'POST',
    data
  });
}

export const updateProject = (data, projectId) => {
  return requestWithToken({
    url: `/projects/${projectId}`,
    method: 'PUT',
    data
  });
}

export const deleteProject = (projectId) => {
  return requestWithToken({
    url: `/projects/${projectId}`,
    method: 'DELETE'
  });
}

export const fetchProjectById = (projectId) => {
  return requestWithToken({
    url: `/projects/${projectId}`,
    method: 'GET',
  });
}

export const fetchTaxByProjectById = (projectId) => {
  return requestWithToken({
    url: `/projects/${projectId}/taxes`,
    method: 'GET',
  });
}

export const createTaxByProjectById = (data, projectId) => {
  return requestWithToken({
    url: `/projects/${projectId}/taxes`,
    method: 'POST',
    data
  });
}

export const fetchCategoryMarkupByProjectById = (projectId) => {
  return requestWithToken({
    url: `/projects/${projectId}/category_markup`,
    method: 'GET',
  });
}

export const createCategoryMarkupByProjectById = (data, projectId) => {
  return requestWithToken({
    url: `/projects/${projectId}/category_markup`,
    method: 'POST',
    data
  });
}

export const fetchProjectScore = (projectId) => {
  return requestWithToken({
    url: `/projects/${projectId}/pqf_status`,
    method: 'GET'
  });
}


export const duplicateProject = (projectId, data) => {
  return requestWithToken({
    url: `/projects/${projectId}/copy`,
    method: 'POST',
    data
  });
}