import { useRoutes, useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import * as actions from "../store/actions/notifications";
import * as authActions from "../store/actions/auth";

// routes
import MainRoutes from './MainRoutes';

export default function Routes() {
  const location = useLocation();
  const dispatch = useDispatch();
  if (location.pathname !== '/login' && location.pathname !== '/register') {
    dispatch(actions.getNotifications());
    dispatch(authActions.getUserInfo());
  }
  // else if (location.pathname === '/login') {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   dispatch({type: "CLEAR_STORE"});
  // }
  return useRoutes(MainRoutes);
}
