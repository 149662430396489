import React, { useEffect, useState } from "react";
import jwt from 'jwt-decode'
import { useNavigate } from "react-router";
import { useMsal } from '@azure/msal-react';
import { useSearchParams } from "react-router-dom";
import  {useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer'
import { message } from 'antd';

import "antd/dist/antd.min.css";
import '../../styles/default.scss';
import {
  Layout,
  Spin,
  Space,
  Select
} from "antd";

import CookieList from "../../constants/cookieList";
import CookieService from "../../services/cookie.service";

import ProvisionLogo from '../../assets/images/logo.svg'
import HeaderMenu from "../../elements/HeaderMenu";
import UnsavedModal from "../Modal/UnsavedModal";
import * as actions from "../../store/actions/projects";
import { signOutUser } from "../../store/actions/auth";
import AuthService from "../../services/auth.service";
import CustomSpin from "../../elements/CustomLoader";
import { downloadFileToSystem } from "../../utils/globalFunctions";

const { Header } = Layout;
const { Option } = Select;

const TopNavBar = (props) => {
  const { instance, accounts  } = useMsal();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState(localStorage.getItem('accessToken') ? jwt(localStorage.getItem('accessToken')) : {});

  const [searchParams] = useSearchParams();

  const [isloading, setloading] = useState(false);
  const [project, setProject] = useState(searchParams?.get('projectId') || null);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProject, setIsProject] = useState(false);
  const [isLogoClicked, setIsLogoClicked] = useState(false);

  const { projectList, userRole, releaseVersion } = useSelector((state) => {
    return {
      projectList: state?.projects?.projects || [],
      userRole: state?.auth?.user?.user?.role?.roleSlug,
      releaseVersion: state?.auth?.user?.releaseVersion?.releaseNumber
    }
  });

  const logoutUser = (key) => {
    setloading(true);
    if (!!accounts?.length) {
      instance.logoutPopup();
    }
    dispatch(signOutUser()).then(() => {
      setloading(false);
      message.success("Logged Out Successfully.");
      window.location.reload();
    })
  }

  const alertUser = (e) => {
    localStorage.setItem('currentReleaseVersion', releaseVersion);
    // e.preventDefault();
    e.stopPropagation();
    e.returnValue = '';
    return undefined;
  }

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [releaseVersion]);

  const refershToken = () => {
    AuthService.callRefresh()
      .then((res) => {
        localStorage.setItem('accessToken', res?.data?.data?.tokens?.accessToken);
        localStorage.setItem('refreshToken', res?.data?.data?.tokens?.refreshToken);
        setToken(res?.data?.data?.tokens?.accessToken);
        return res
      })
      .catch((err) => {
        if (err !== "Refresh token not found") {
          message.error(err?.message || err?.data?.message);
        }
        navigate("/login");
        dispatch(signOutUser()).then(() => {
          if (props?.setloading) {
            props?.setloading(false);
          }
          message.success("Logged Out Successfully.");
        })
      });
  }

  const onIdle = () => {
    console.log("OnIdle");
    refershToken();
  }

  const onActive = () => {
    console.log("OnActive");
  }

  useIdleTimer({
    onIdle,
    onActive,
    timeout: 600000,
    throttle: 500
  })

  useEffect(() => {
    if (!projectList?.length) {
      getProjectsData();
    } else {
      setProject(searchParams?.get('projectId'));
    }
    return () => {
      setProject(null);
    }
  }, [searchParams]);

  const onProjectChange = (e, record) => {
    setProject(e);
    if (userRole === 'admin' || userRole === 'manager' || userRole === 'client' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator') {
      navigate(`/ProjectInformation?projectId=${record?.value}&tab=logistics`)
    } else if (userRole === 'vendor') {
      navigate(`/RFPDashboardManagement?projectId=${record?.value}&tab=costing`)
    }
  };

  const getProjectsData = async () => {
    dispatch(actions.getProjects());
  };

  const checkUnsaved = () => {
    let value = CookieService.get(CookieList.unsavedValue);
    let value2 = CookieService.get(CookieList.createRequest);

    if (value === true || value === "true") {
      setIsModalVisible(true);
      setDropDownOpen(false);
    } else if ((value2 === "true" || value2 === true)) {
      setIsModalVisible(true);
      setDropDownOpen(false);
    }
    else {
      setIsModalVisible(false);
      if (dropDownOpen) {
        setDropDownOpen(false);
      }
      else {
        setDropDownOpen(true);
      }
    }
  }

  const clearCookies = () => {
    setIsModalVisible(false);
    CookieService.remove(CookieList.unsavedValue);
    CookieService.set(CookieList.unsavedValue, false);
    CookieService.remove(CookieList.createRequest);
    CookieService.set(CookieList.createRequest, false);
  }

  const handleOk = () => {
    if(isProject) {
      setDropDownOpen(true);
    } else {
      setProject(null);
    }
    clearCookies();
    if (!!isLogoClicked) {
      navigate('/');
    }
  };

  return (
    <div>
      <CustomSpin spinning={isloading} tip="Logging You Out. Please Wait.">
        <Header className="hide-pc-menu main-header">
          <div className="float-left">
            <Space>
              <a onClick={() => {
                setProject(null);
                let value = CookieService.get(CookieList.unsavedValue);
                if (value === true || value === "true") {
                  setIsModalVisible(true);
                  setIsLogoClicked(true);
                } else {
                  navigate('/')
                }
              }}>
                <img src={ProvisionLogo} width="100" height="50" className="mr-20" />
              </a>

              <div className="pro-top-search-2">
                <Space>
                  <Select
                    id="select_header_project"
                    placeholder="Select Project/Property"
                    showSearch
                    className="text-left select-proj-width"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    value={projectList?.length ? project : null}
                    onChange={(e, record) => {
                      onProjectChange(e, record)
                    }}
                    open={dropDownOpen}
                    onDropdownVisibleChange={() => {
                      checkUnsaved()
                      setIsProject(true)
                    }}
                  >
                    {projectList.map((item) => (
                      <Option
                        value={item.id}
                        title="Project/Property"
                        key={item.id}
                        projdata={item}
                        className="header_project_option_item"
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Space>
              </div>
              {
                userRole === 'vendor' && (searchParams?.get('tab') === 'costing' || searchParams?.get('tab') === 'issuerfp') ?
                  <div>
                    <Space className="noti-timestamp" style={{ width: "200px", marginLeft: "1.5rem", textAlign: "right" }}>
                      <a onClick={async () => {
                        await downloadFileToSystem(`/RFP_Response_Guide.pdf`, 'RFP_Response_Guide');
                      }}>
                        <i>Instruction for RFP response</i>
                      </a>
                    </Space>
                  </div>
                : null
              }
              {
                releaseVersion && releaseVersion != token?.user?.version && localStorage.getItem('currentReleaseVersion') != releaseVersion ?
                  <div>
                    <Space className="noti-timestamp" style={{ width: "350px", marginLeft: "0.5rem", textAlign: "right" }}>
                      <a onClick={() => {
                        // localStorage.setItem('currentReleaseVersion', releaseVersion);
                        // window.location.reload();
                        logoutUser();
                      }}>
                        New release is available, please logout and log back in.
                      </a>
                    </Space>
                  </div>
                : null
              }
            </Space>
          </div>
          <HeaderMenu isloading={isloading} setloading={setloading} setProject={setProject} />
          <div className="clear-float"></div>
          <div className="clear-float"></div>
          <UnsavedModal
            className="header-unsaved"
            isModalVisible={isModalVisible}
            handleOk={handleOk}
            handleCancelUnsave={() => setIsModalVisible(false)}
          />
        </Header>
      </CustomSpin>
    </div>
  );
};

export default TopNavBar;
