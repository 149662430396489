import { combineReducers } from 'redux';

import authReducer from "./auth";
import projectReducer from './projects';
import notificationReducer from './notifications';

const appReducer = combineReducers({
    auth: authReducer,
    projects: projectReducer,
    notifications: notificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    state = {
      auth: {
        registerMessage: state.auth?.registerMessage,
      }
    }
  }
  return appReducer(state, action)
}

export default rootReducer;
